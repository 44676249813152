import React from "react"
import styled from "styled-components"
import { useHidden } from "../../hooks/useHidden"
import { Link } from "gatsby"
import LogoImage from "../images/LogoImage"

const Bar = styled.div`
  display: flex;
  visibility: ${props => props.visible};
  min-height: 70px;
  top: 0;
  background-color: ${props => props.bg};
  left: 0;
  justify-content: space-between;
`

const Logo = styled(LogoImage)``

const MenuLink = styled(Link)``

const Hamburger = styled.button`
  height: 40px;
  width: 52px;
  background: transparent;
  border: none;
  margin: 1rem;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const HamburgerLine = styled.div`
  width: 42px;
  height: 5px;
  background-color: white;
`

const NavLink = styled(Link)`
  padding: 10px;
  margin: 2px 0px;
  background-color: white;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  color: #3e3d3d;
  border-left: 6px solid #337ab7;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #17669c;
    color: white;
  }
`

const SideMenu = styled.div`
  height: 100%;
  background-color: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  visibility: ${props => props.visible};
  position: fixed;
  background-color: #66afe9d1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20%;
  -webkit-transform: ${props => props.translate};
  -ms-transform: ${props => props.translate};
  -moz-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out;
  transform: ${props => props.translate};
  transition: transform 0.3s ease-out;
  min-width: 250px;
  z-index: 200;
  overflow-y: scroll;
`

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const Backdrop = styled.div`
  position: fixed;
  visibility: ${props => props.visible};
  width: 100%;
  height: 100%;
  -webkit-transform: ${props => props.translate};
  -ms-transform: ${props => props.translate};
  transform: ${props => props.translate};
  -moz-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  -webkit-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  background-color: #1f2527;
  opacity: 0.7;
  z-index: 200;
`

const MenuBar = props => {
  const showSideMenu = useHidden(false)
  const showBackdrop = useHidden(false)
  const showMainMenu = useHidden(true)

  const hamburgerClickHandler = () => {
    showSideMenu.setVisible()
    showBackdrop.setVisible()
    showMainMenu.setHidden()
  }

  const backDropHandler = () => {
    showSideMenu.setHidden()
    showBackdrop.setHidden()
    showMainMenu.setVisible()
  }

  const menuItems = props.items.map((item, i) => (
    <NavLink key={i} to={item.node.frontmatter.path}>
      {item.node.frontmatter.name}
    </NavLink>
  ))
  return (
    <>
      <Backdrop
        onClick={() => backDropHandler()}
        translate={showBackdrop.show ? "translateY(0)" : "translateY(-100%)"}
      />
      <Bar bg={props.bg} visible={showMainMenu.show ? "visible" : "hidden"}>
        <MenuLink to="/">
          <Logo imgName="mokkivuokraus.png" />
        </MenuLink>
        <Hamburger onClick={() => hamburgerClickHandler()}>
          <HamburgerLine></HamburgerLine>
          <HamburgerLine></HamburgerLine>
          <HamburgerLine></HamburgerLine>
        </Hamburger>
      </Bar>
      <SideMenu
        translate={showBackdrop.show ? "translateX(0)" : "translateX(-100%)"}
      >
        <SideContainer>
          <MenuLink to="/">
            <Logo imgName="mokkivuokraus.png" />
          </MenuLink>
          {menuItems}
        </SideContainer>
      </SideMenu>
    </>
  )
}

export default MenuBar
