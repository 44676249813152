import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const Image = styled(Img)`
    margin: 1.3rem;
    width: 200px;
    height: 30px;
    z-index: 1000;
`

const LogoImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return <Image fluid={image.node.fluid} alt={imgName.split(".")[0]} />
    }}
  />
)
export default LogoImage
