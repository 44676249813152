import React from "react"
import { createGlobalStyle } from "styled-components"
import { Footer } from "../components/layout/footer/Footer"
import CookieConsent from "react-cookie-consent"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"

// const GlobalStyle = createGlobalStyle`

//   html{
//     height: 100%;
//     scroll-behavior: smooth;
//   }
//   body {
//     background-color: white;
//     height: 100%;
//     padding: 0px;
//     margin: 0px;
//     font-size: 1.2rem;
//     line-height: 1.8rem;
//     font-family: Montserrat,sans-serif;
//   }
//   a { cursor: pointer; text-decoration:none; font-weight:800; color: #1d5d90; }
//   h1 {line-height: 1.2; font-size: 2.5rem; text-align: center;}
//   h2 {line-height: 1.2; font-size: 1.9rem; text-align: left; margin-top: 8rem; margin-bottom: 0rem;}
//   h3 {line-height: 1.2; font-size: 1.3rem; text-align: left; margin-top: 4rem;}
//   h4 {line-height: 1.2; font-size: 2.1rem; text-align: left; margin-top: 0rem; margin-bottom: 0rem;}
//   h6 {line-height: 1.2; font-size: 1.2rem; text-align: left; margin: 0rem; padding: 0rem;  color: blue; font-weight: 800; max-width: 300px; border-radius: 10px; margin-top: 6rem}
//   em {margin: 0rem; padding:0rem}
//   strong{background-color: #2a87d0; color: white; padding: 0.5rem 0.2rem;}
//   button { cursor: pointer;}
//   li {margin-top:0.8rem;}
// `

const theme = extendTheme({
  styles: {
    global: {
      html: {
        height: "100%",
        scrollBehavior: "smooth",
      },
      body: {
        backgroundColor: "white",
        height: "100%",
        padding: "0px",
        margin: "0px",
        fontSize: "1.2rem",
        lineHeight: "1.8rem",
        fontFamily: "Montserrat,sans-serif",
      },
      a: {
        cursor: "pointer",
        textDecoration: "none",
        fontWeight: "800",
        color: "#1d5d90",
      },
      h1: { lineHeight: "1.2", fontSize: "2.5rem", textAlign: "center" },
      h2: {
        lineHeight: "1.2",
        fontSize: "1.9rem",
        textAlign: "left",
        marginTop: "8rem",
        marginBottom: "1rem",
      },
      h3: {
        lineHeight: "1.2",
        fontSize: "1.3rem",
        textAlign: "left",
        marginTop: "4rem",
        marginBottom: "1rem",
      },
      h4: {
        lineHeight: "1.2",
        fontSize: "2.1rem",
        textAlign: "left",
        marginTop: "2rem",
        marginBottom: "1rem",
      },
      h5: {
        lineHeight: "1.2",
        fontSize: "2rem",
        textAlign: "left",
        marginTop: "2rem",
        marginBottom: "1rem",
      },
      h6: {
        lineHeight: "1.2",
        fontSize: "1.2rem",
        textAlign: "left",
        margin: "0rem",
        padding: "0rem",
        color: "blue",
        fontWeight: "800",
        maxWidth: "300px",
        borderRadius: "10px",
        marginTop: "6rem",
      },
      em: { margin: "0rem", padding: "0rem" },
      strong: {
        backgroundColor: "#2a87d0",
        color: "white",
        padding: "0.5rem 0.2rem",
      },
      button: { cursor: "pointer" },
      li: { marginTop: "0.8rem" },
    },
  },
})
const GlobalStyles = props => {
  return (
    <ChakraProvider theme={theme}>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="lpCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Tällä sivustolla käytetään evästeitä. Jatkamalla sivuston käyttöä
          hyväksyt evästeiden käytön.
        </CookieConsent>
        {props.children}
        <Footer />
      </div>
    </ChakraProvider>
  )
}

export default GlobalStyles
