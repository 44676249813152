import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  text-align: center;
  background-color: #337ab7;
`
const Section = styled.div`
  padding: 1rem;
  color: white;
`

const Link = styled.a`
  text-decoration: none;
`

export const Footer = () => {
  return (
    <Container>
      <Section>© Copyright 2020 mokkivuokraus.fi</Section>
      <Link href="/tietoa-palvelusta">
        <Section>Tietoa palvelusta</Section>
      </Link>
      <Link href="/ota-yhteytta">
        <Section>Ota yhteyttä</Section>
      </Link>
    </Container>
  )
}
